function Dashboard() {
	return (
		<div className="DashBoard">
			<h1>Accueil</h1>
			<div>
				<h4>La page d'accueil est en cours de construction... Vous pouvez naviguer via la barre à gauche !</h4>
			</div>
		</div>
	);
}

export default Dashboard;
