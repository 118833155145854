function MobilePage() {
	return (
		<div className="MobilePage">
			Ce site n'est pas disponible sur mobile.
			<br />
			Visitez-le sur PC !
		</div>
	);
}

export default MobilePage;
